<template>
  <div class="h-full">
    <div class="console-dash-menu-container">
      <div class="console-dash-menu">
        <u-btn @click="$router.push({name: 'console.clientes.cliente'})" class="opt-btn" :class="{active: $route.name === 'console.clientes.cliente'}"><i class="fa-duotone fa-user" /> Resumo</u-btn>
        <u-btn @click="$router.push({name: 'console.clientes.cliente.financeiro'})" class="opt-btn" :class="{active: $route.name === 'console.clientes.cliente.financeiro'}"><i class="fa-duotone fa-money-check" /> Financeiro</u-btn>
      </div>
    </div>

    <div class="suporte-cliente-tarefas cli-task-page" v-if="$route.name === 'console.clientes.cliente'">
      <div class="nav">
        <a href="/#/console">Console</a>
        <i class="fa fa-chevron-right" />
        <a href="/#/console/cilentes/lista">Clientes</a>
        <i class="fa fa-chevron-right" />
        <span class="nav-end">Cliente ID #{{cliente && cliente.id}}</span>
      </div>

      <div v-if="!loading && cliente" class="case">
        <div class="case-header">
          <div class="id">
            Cliente {{cliente.pessoa.name}}
          </div>
          <div class="actions">
            <satisfacao-rating class="m-t-n-xxl" :cliente="cliente" />
          </div>
        </div>
        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Detalhes do cliente</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
                <cliente-menu-right />
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <e-row class="def full-width" mr>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Nome">{{ cliente.pessoa.name }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Domínio principal">
                      <a :href="'https://' + cliente.dominio" target="_blank">{{cliente.dominio}}</a>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Documento">{{ cliente.pessoa.document|formataCpfCnpj2 }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Tipo do cliente">
                      {{ cliente.pessoa.type === 1 ? 'Pessoa Física' : 'Pessoa Jurídica' }}
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="E-mail">
                      <div class="lowercase" v-if="cliente.pessoa.emails.length">{{ cliente.pessoa.emails.map(e => e.email).join(', ') }}</div>
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Telefone">
                      <div v-if="cliente.pessoa.phoneNumbers.length">{{ cliente.pessoa.phoneNumbers.map(e => e.phoneNumber).join(', ') }}</div>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Criado">{{ cliente.createdAt|formatDate }}</task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Última atualização">
                      <div v-if="cliente.dateModified">{{ cliente.dateModified|formatDate }}</div>
                      <div v-else>-</div>
                    </task-detail>
                  </e-col>
                </e-row>
              </e-col>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Gerente">
                      <gerente-edit />
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Status">
                      <status-cliente :cliente="cliente" />
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row class="m-t-n" mr>
                  <e-col>
                    <task-detail title="Plano">
                      {{cliente.plano.nome}}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Infra">
                      {{cliente.servidor.nome}}
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Mensalidade">
                      {{cliente.mensalidade|moeda}}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Periodicidade">
                      <div v-if="cliente.mensalidadePeriodicidade === 1">Mensal</div>
                      <div v-if="cliente.mensalidadePeriodicidade === 2">Bimestral</div>
                      <div v-if="cliente.mensalidadePeriodicidade === 3">Trimestral</div>
                      <div v-if="cliente.mensalidadePeriodicidade === 6">Semestral</div>
                      <div v-if="cliente.mensalidadePeriodicidade === 12">Anual</div>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <task-detail title="Dia de Vencimento">
                      {{cliente.mensalidadeDiaVencimento}}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Próximo Vencimento">
                      <div v-if="cliente.mensalidadeProximoVencimento">{{cliente.mensalidadeProximoVencimento|formatDate('dd/MM/yyyy')}}</div>
                      <div v-else>Nunca</div>
                    </task-detail>
                  </e-col>
                </e-row>
                <e-row mr>
<!--                  <e-col>
                    <task-detail title="Último Pagamento">
                      <div v-if="cliente.mensalidadeUltimoPagamento">{{cliente.mensalidadeUltimoPagamento|formatDate('dd/MM/yyyy')}}</div>
                      <div v-else>Nunca</div>
                    </task-detail>
                  </e-col>-->
                  <e-col>
                    <task-detail title="Início do Contrato">
                      <div>
                        <div v-if="cliente.contratoInicio">{{cliente.contratoInicio|formatDate('dd/MM/yyyy')}}</div>
                        <div v-else>Nunca</div>
                        <div v-if="!cliente.contratoAssinado" class="m-t-xs">
                          <span class="trask-atraso-lbl"><i class="fas fa-exclamation-triangle m-r-xs"></i> Não assinado</span>
                        </div>
                      </div>
                    </task-detail>
                  </e-col>
                </e-row>
              </e-col>
            </e-row>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Observações sobre o cliente</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <div style="padding-bottom: 30px">
              <div v-if="cliente.observacao">{{cliente.observacao}}</div>
              <div v-else>Nada informado</div>
            </div>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Pasta de contratos, documentos, fiscal e principais arquivos</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <div style="padding-bottom: 30px; width: 100%">
              <cliente-anexos :cliente="cliente" />
              <anexo-uploads ref="attachments" :cliente="cliente" :autosend="true" disable-load-files class="m-t"  />
            </div>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">CRM</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <div class="mod-pessoa" style="padding-bottom: 30px; width: 100%">
              <pessoa-abas :pessoa="cliente.pessoa" />
              <pessoa-planejamento :pessoa="cliente.pessoa" /><!-- END Box -->
              <pessoa-historico :pessoa="cliente.pessoa" /><!-- END Box -->
            </div>
          </div>
        </div>

        <div class="case-tab">
          <div class="tab-header">
            <e-row class="full-width">
              <e-col class="m-b-none">Estatísticas</e-col>
              <e-col class="text-right m-b-none flex items-center justify-end">
              </e-col>
            </e-row>
          </div>
          <div class="tab-content starefa-page">
            <e-row class="def full-width" mr>
              <e-col>
                <e-row mr>
                  <e-col>
                    <task-detail title="Leilões">
                      {{cliente.statLeiloes|number}}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Bens">
                      {{cliente.statBens|number}}
                    </task-detail>
                  </e-col>
                </e-row><e-row mr>
                  <e-col>
                    <task-detail title="Base Cadastral">
                      {{cliente.statClientes|number}}
                    </task-detail>
                  </e-col>
                  <e-col>
                    <task-detail title="Vendas">
                      {{cliente.statVendas|number}}
                    </task-detail>
                  </e-col>
                </e-row>
              </e-col>
               <e-col>
                 <e-row mr>
                   <e-col>
                     <task-detail title="Ticket In">
                       {{cliente.ticketIn|number}}
                     </task-detail>
                   </e-col>
                   <e-col>
                     <task-detail title="Ticket Out">
                       {{cliente.ticketOut|number}}
                     </task-detail>
                   </e-col>
                 </e-row>
              </e-col>
            </e-row>
          </div>
        </div>

        <!-- -->
      </div>
      <div v-else class="case">
        <sl-loading class="size-18" content="" />
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>

import SlLoading from "components/layout/components/Loading.vue"
import {find} from "@/domain/gerencia/clientes/services"
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import TaskDetail from "components/suporte/cliente/components/include/TaskDetail.vue";
import ClienteMenuRight from "components/clientes/components/cliente/include/ClienteMenuRight.vue";
import clienteWindow from "components/clientes/components/cliente/window/cliente";
import SatisfacaoRating from "components/clientes/components/cliente/include/SatisfacaoRating.vue";
import GerenteEdit from "components/clientes/components/cliente/include/GerenteEdit.vue";
import StatusCliente from "components/clientes/components/cliente/include/StatusCliente.vue";
import ClienteAnexos from "components/console/components/clientes/include/attachment/files.vue";
import AnexoUploads from "components/console/components/clientes/include/attachment/Attachments.vue";
import PessoaAbas from "components/pessoa/components/include/pessoa/PessoaAbas.vue";
import PessoaPlanejamento from "components/pessoa/components/include/pessoa/Planejamento.vue";
import PessoaHistorico from "components/pessoa/components/include/pessoa/Historico.vue";

export default {
  name: "ConsoleCliente",
  provide: function () {
    return {
      clienteContainer: this,
      appContainer: this,
    }
  },
  mixins: [],
  computed: {
  },
  data () {
    return {
      loading: true,
      cliente: null
    }
  },
  components: {
    PessoaHistorico, PessoaPlanejamento,
    PessoaAbas,
    AnexoUploads,
    ClienteAnexos,
    StatusCliente,
    GerenteEdit,
    SatisfacaoRating,
    ClienteMenuRight,
    TaskDetail,
    ERow,
    ECol,
    SlLoading
  },
  beforeCreate() {
    document.body.classList.add('cli-task-body')
    document.body.classList.add('cli-page-body')
  },
  beforeDestroy() {
    document.body.classList.remove('cli-task-body')
    document.body.classList.remove('cli-page-body')
  },
  mounted() {
    const element = document.querySelector('.u-erp-layout-container');
    element.scrollTop = 0;
    this.load()
  },
  watch: {
    '$route.params.id' (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.$route.params.id)
          .then(response => {
            this.cliente = response.data
            setTimeout(() => {
              document.title = `Cliente #${this.cliente.pessoa.name} - SL`
            }, 2)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    clienteWindow,
    edit () {
      this.clienteWindow(this.cliente.id)
    }
  },
  meta: {
    title: 'Cliente'
  }
}
</script>

<style lang="stylus">
.cli-page-body {
  .console-dash-menu-level1 {
    display none
  }
  .fin-container {
    background transparent !important
  }
}
</style>
