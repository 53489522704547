<script>
export default {
  name: "TaskDetail",
  props: {
    title: {required: false}
  }
}
</script>

<template>
  <div class="detail">
    <slot name="title">
      <div class="title">{{ title }}</div>
    </slot>
    <div class="value"><slot /></div>
  </div>
</template>
