<script>
import ClienteAttach from "./attach"
import ClienteAttachmentsFiles from "./files"
import uploadareaMixin from "components/console/components/clientes/include/attachment/uploadareaMixin"

export default {
  name: 'ClienteAnexosUpload',
  mixins: [uploadareaMixin],
  provide: function () {
    return {
      attachContainer: this
    }
  },
  props: {
    cliente: {
      required: true
    },
    autosend: {
      type: Boolean,
      default: true
    },
    disableLoadFiles: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      files: []
    }
  },
  computed: {
    classes () {
      let css = []
      return css
    },
  },
  mounted () {
  },
  destroyed () {
  },
  methods: {},
  components: {ClienteAttachmentsFiles, ClienteAttach}
}
</script>

<template>
  <div class="task-attach-dropzone no-wrap h-full flex column content-between"
       :class="{'dropzoneEnter': dropzone, 'done': false}"
       @dragenter.prevent.stop="dropzoneEnter"
       @dragover.prevent.stop="dropzone = true"
       @drop.prevent.stop="inputListener"
       @dragleave.prevent.stop="dropzone = false">
    <input @change="inputListener($event)" :accept="fileTypes" type="file" id="input" ref="input" multiple
           v-show="false">
    <div class="task-attachments col-grow-1" :class="classes">
      <cliente-attach :cliente="cliente"/>
      <cliente-attachments-files v-if="!disableLoadFiles" :cliente="cliente"/>
    </div>
  </div>
</template>
