<script>
import ClienteFile from "./file"
import globalPersonMixin from "@/components/pessoa/globalPersonMixin"

export default {
  name: 'cliente-attachments-files',
  mixins: [globalPersonMixin],
  props: {
    cliente: Object,
    comment: {
      type: Object,
      required: false
    },
    isGerencia: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      //files: MockFiles
    }
  },
  computed: {
    attachments() {
      if (!this.cliente || !this.cliente.anexos || !this.cliente.anexos.length) {
        return null
      }
      return this.cliente.anexos.map(file => {
        const fileinfo = file.originalFilename.split('.')
        const extension = fileinfo[fileinfo.length - 1]
        return {
          ...file,
          extension: extension
        }
      })
    }
  },
  methods: {},
  components: {ClienteFile}
}
</script>

<template>
  <div class="cliente-files m-b-lg">
    <ul>
      <li :key="file.id" v-for="file in attachments">
        <cliente-file :cliente="cliente" :file="file"/>
        <div class="desc">Enviado {{ file.createdAt|formatDate }} por {{ firstLastName(file.createdByName) }}</div>
      </li>
    </ul>
  </div>
</template>
